<!--  -->
<template>
  <div class="file-folder-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '知识库',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="view-tabs" v-if="viewTabs">
      <p class="view-name">
        <span v-for="(tab, i) in viewTabs" :key="i" @click="handleTab(tab, i)"
          >{{ tab.name }} <i v-if="i < viewTabs.length - 1">/</i>
        </span>
      </p>
      <div class="opt-btn">
        <p class="new-folder" @click="handleAdd">新建目录</p>
        <p
          class="del-folder"
          v-if="foldersFiles.length > 0"
          @click="handleDelFolder"
        >
          删除目录
        </p>
      </div>
    </div>
    <div class="list-box">
      <div class="file-folder" v-if="foldersFiles.length > 0">
        <div class="item" v-for="(item, i) in foldersFiles" :key="i">
          <span class="check-box">
            <van-checkbox
              v-if="item.isFolder == 1"
              icon-size="0.24rem"
              v-model="item.checked"
              shape="square"
            ></van-checkbox>
          </span>

          <span class="icon">
            <!-- <van-icon
              v-if="item.isFolder == 1"
              name="card"
              size="0.75rem"
              color="#008DF0"
            />
            <van-icon
              name="coupon"
              v-if="item.isFolder == 0"
              size="0.75rem"
              color="#008DF0"
            />-->
            <img
              class="icon"
              style="width: 0.75rem; heihgt: 0.75rem"
              fit="contain"
              :src="iconName(item)"
            />
          </span>
          <div
            class="right-box"
            @click="item.isFolder == 1 ? handleFolder(item) : handleFile(item)"
          >
            <div class="left-info">
              <p class="item-name">{{ item.folderName }}</p>
              <p class="item-info">
                <span class="user-name">{{ item.createUserName || "--" }}</span>
                <span class="time"
                  >{{ item.updateTime || "2023-07-12" }} 更新</span
                >
              </p>
            </div>
            <van-icon
              v-if="item.isFolder == 1"
              class="icon"
              name="arrow"
              size="0.2rem"
            />
          </div>
        </div>
      </div>
      <van-empty v-else />
    </div>
    <!-- 操作文件面板 -->
    <van-action-sheet class="action-sheet" v-model="isOptShow">
      <p class="action" @click="handleDown()">下载</p>
      <p class="action" @click="handleSee()">预览</p>
      <p class="action" @click="handleDel()">删除</p>
      <p class="action" @click="handleMove()">移动</p>
    </van-action-sheet>
    <!-- 移动 -->
    <van-popup
      v-model="showMove"
      position="bottom"
      class="popup-box"
      :lock-scroll="false"
      :style="{
        width: '100%',
        height: '80%',
        padding: '0.3rem',
        overflowY: 'auto',
        overflowX: 'hidden',
      }"
    >
      <div class="view-tabs" v-if="moveViews.length > 0">
        <span
          v-for="(tab, i) in moveViews"
          :key="i"
          @click="handleMoveTab(tab, i)"
          >{{ tab.name }} <i v-if="i < moveViews.length - 1">/</i>
        </span>
      </div>
      <div class="list-box">
        <div class="file-folder">
          <div
            class="item"
            v-for="(item, i) in folders"
            :key="i"
            @click="handleMoveTo(item)"
          >
            <van-icon name="card" size="0.75rem" color="#008DF0" />
            <span class="floder-name">{{ item.folderName }}</span>
          </div>
        </div>
      </div>
      <div class="opt-btn-bottom">
        <el-button type="primary" style="width: 100%" @click="onMoveSure"
          >添加到此</el-button
        >
      </div>
    </van-popup>
    <!-- 新建目录 -->
    <van-popup
      v-model="showAdd"
      position="bottom"
      class="popup-box"
      :lock-scroll="false"
      :style="{
        width: '100%',
        height: '50%',
        padding: '0.3rem',
        overflowY: 'auto',
        overflowX: 'hidden',
      }"
    >
      <van-form @failed="onFailed" class="main-wrapper" ref="addFolder">
        <van-field
          v-model="form.folderName"
          name="folderName"
          label="文件名称"
          required
          placeholder="请入文件名称"
          maxlength="20"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.spaceName"
          name="spaceName"
          label="文件空间"
          required
          placeholder="请入文件空间"
          maxlength="20"
          disabled
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-if="form.type && form.type == 2"
          v-model="form.departmentCode"
          name="departmentCode"
          label="所属部门"
          required
          placeholder="请入所属部门"
          disabled
          :rules="[{ required: true, message: '' }]"
        />
      </van-form>
      <div class="btn-box">
        <van-button
          round
          size="small"
          native-type="button"
          type="info"
          @click="sureAdd"
          style="width: 100%"
          >确认添加</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { downLoadFile } from "@/api/fileManager/index.js";
import { Toast } from "vant";

import { mapActions, mapState, mapMutations } from "vuex";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      ischeckShow: false,
      isOptShow: false,
      curId: "",
      showMove: false,
      showAdd: false,
      form: {
        folderType: null,
        folderName: "",
        departmentCode: "",
        spaceName: "",
        folderCode: "",
      },
    };
  },
  // watch: {
  //   $route: {
  //     handler() {
  //       console.log("获取其他数据等操作");
  //       this.resetViewTabs();
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // },

  components: { TopWrapper},

  computed: {
    ...mapState("fileManager", [
      "menuList",
      "departmentList",
      "viewTabs",
      "foldersFiles",
      "moveViews",
      "curFile",
      "folders",
    ]),
    selectedFolders: {
      get() {
        let a = [];
        this.foldersFiles.map((e) => {
          if (e.checked == true) {
            a.push(e);
          }
        });
        return a;
      },
      set() {},
    },
  },

  mounted() {
    if (this.$route.query) {
      let { id, departmentCode, type, name } = this.$route.query;
      this.resetViewTabs();
      this.setViewTabs({
        name: name,
        code: id,
        departmentCode: departmentCode,
        type: type,
      });
      this.curId = id;
      this.getFoldersFiles({
        departmentCode: departmentCode,
        folderCode: id,
        folderType: type,
      });
    }
  },

  methods: {
    ...mapMutations("fileManager", [
      "setViewTabs",
      "delViewTabs",
      "resetMoveViews",
      "delMoveViews",
      "setMoveViews",
      "setCurFile",
      "resetViewTabs",
    ]),
    ...mapActions("fileManager", [
      "getFoldersFiles",
      "delFileFol",
      "getFolderListAction",
      "moveFileToFolder",
      "addFolderAction",
    ]),
    iconName(folder) {
      // console.log(folder);
      if (folder.isFolder == 1) {
        return require("@/assets/images/fileManager/folder.png");
      } else {
        switch (folder.fileType) {
          case "png":
          case "jpg":
          case "jpeg":
          case "gif":
            return folder.filePath;
          case "pdf":
            return require("@/assets/images/fileManager/pdf.png");
          case "ppt":
          case "pptx":
            return require("@/assets/images/fileManager/ppt.png");
          case "xls":
          case "xlsx":
            return require("@/assets/images/fileManager/xls.png");
          case "doc":
          case "docx":
          case "txt":
            return require("@/assets/images/fileManager/doc.png");
          case "rar":
          case "zip":
            return require("@/assets/images/fileManager/rar.png");
          default:
            return require("@/assets/images/fileManager/txt.png");
        }
      }
    },
    handleDelFolder() {
      if (this.selectedFolders.length > 0) {
        let arr = [];
        this.selectedFolders.map((e) => {
          let obj = {
            type: 1,
            code: e.folderCode,
          };
          arr.push(obj);
        });
        this.delFileFol(arr)
          .then((res) => {
            let { code, msg } = res;
            if (code == 200) {
              this.selectedFolders = [];
              Toast.success(msg);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        Toast("未选择删除文件目录");
      }
    },
    handleFolder(item) {
      // console.log(item, "=====item====");

      if (item.isFolder == 1) {
        this.setViewTabs({
          name: item.folderName,
          code: item.folderCode,
          departmentCode: item.departmentCode || "",
          type: item.folderType,
        });
        this.getFoldersFiles({
          departmentCode: item.departmentCode || "",
          folderCode: item.folderCode,
          folderType: item.folderType,
        });
      }
    },
    handleFile(item) {
      // console.log(this.viewTabs, ";;;;;;;;;;;;");
      this.setCurFile(item);
      this.isOptShow = true;
    },
    handleTab(tab, i) {
      this.delViewTabs(i + 1);
      this.getFoldersFiles({
        departmentCode: tab.departmentCode || "",
        folderCode: tab.code,
        folderType: tab.type,
      });
    },
    handleDown() {
      if (this.curFile) {
        if (this.curFile.fileType != "exe") {
          downLoadFile([this.curFile.fileCode])
            .then((res) => {})
            .catch((e) => {
              // 创建新的URL并指向File对象或者Blob对象的地址
              const blobURL = window.URL.createObjectURL(e);
              // 创建a标签，用于跳转至下载链接
              const tempLink = document.createElement("a");
              tempLink.style.display = "none";
              tempLink.href = blobURL;
              tempLink.setAttribute("download", decodeURI("下载文件"));
              // 兼容：某些浏览器不支持HTML5的download属性
              if (typeof tempLink.download === "undefined") {
                tempLink.setAttribute("target", "_blank");
              }
              // 挂载a标签
              document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(tempLink);
              // 释放blob URL地址
              window.URL.revokeObjectURL(blobURL);
            });
        } else {
          Toast(`可在浏览器端打开下载${this.curFile.fileType}文件`);
          return;
        }
      } else {
        Toast.loading("未获取到下载文件");
        return;
      }
    },
    handleSee() {
      // window.open(this.curFile.filePath);
      this.$router.push({
        path: "/fileScan",
        query: {
          url: encodeURIComponent(this.curFile.filePath),
          fileName: this.curFile.fileName,
        },
      });
    },
    handleDel() {
      this.delFileFol([
        {
          type: this.curFile.isFolder,
          code: this.curFile.folderCode,
        },
      ])
        .then((res) => {
          let { code, msg } = res;
          if (code == 200) {
            Toast.success(msg);
            this.isOptShow = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleMove() {
      this.initData();
      this.showMove = true;
    },
    initData() {
      this.resetMoveViews();
      // this.setMoveViews(this.curFile);
      if (this.$route.query.type == 2) {
        this.getFolderListAction({
          folderCode: this.$route.query.id,
          departmentCode: this.$route.query.departmentCode,
        });
      } else {
        this.getFolderListAction({
          folderCode: this.$route.query.id,
          departmentCode: this.$route.query.departmentCode,
        });
      }
      this.setMoveViews({
        name: this.$route.query.name,
        code: this.$route.query.id,
        departmentCode: this.$route.query.departmentCode,
        type: this.$route.query.type,
      });
    },
    handleMoveTab(tab, i) {
      if (i > 0) {
        this.delMoveViews(i + 1);
        if (tab.type == 2) {
          this.getFolderListAction({
            folderCode: tab.code,
            departmentCode: tab.departmentCode,
          });
        } else {
          this.getFolderListAction({
            folderCode: tab.code,
          });
        }
      } else {
        this.initData();
      }
    },
    handleMoveTo(item) {
      // console.log("选中亦如文件夹", item);
      this.setMoveViews({
        code: item.folderCode,
        name: item.folderName,
        type: item.folderType,
        departmentCode: item.folderCode,
      });
      if (item.folderType == 2) {
        this.getFolderListAction({
          folderCode: item.folderCode,
          departmentCode: item.folderCode,
        });
      } else {
        this.getFolderListAction({
          folderCode: item.folderCode,
        });
      }
    },
    onMoveSure() {
      let arr = JSON.parse(JSON.stringify(this.moveViews));
      const lastViews = arr.pop();
      // console.log(lastViews, "--------lastViews--------");

      const params = {
        inCode: lastViews.code,
        outCode:
          this.curFile.isFolder == 1
            ? this.curFile.folderCode
            : this.curFile.fileCode,
      };
      // console.log(params, "----------params-----");

      this.moveFileToFolder(params).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$message({
            type: "success",
            message: msg,
          });

          let navs = JSON.parse(JSON.stringify(this.viewTabs));
          let last = navs.pop();
          this.getFoldersFiles({
            folderCode: last.code,
            departmentCode: last.departmentCode,
            folderType: last.type,
          });
          this.showMove = false;
          this.isOptShow = false;
        }
      });
    },
    handleAdd() {
      let arr = JSON.parse(JSON.stringify(this.viewTabs));
      let lastTab = arr.pop();
      // console.log(lastTab, "####lastTab####");
      if (lastTab) {
        this.form.folderCode = lastTab.code;
        this.form.folderType = this.$route.query.type;
        this.form.spaceName =
          this.$route.query.type == "2" ? "部门空间" : this.$route.query.name;
        this.form.departmentCode = lastTab.departmentCode;
      }
      this.showAdd = true;
    },
    onFailed() {},
    sureAdd() {
      this.$refs.addFolder
        .validate(["folderType", "folderName", "departmentCode"])
        .then((res) => {
          this.addFolderAction(this.form).then((res) => {
            let { code, msg } = res;
            if (code == 200) {
              Toast(msg);
              this.form = {
                folderType: null,
                folderName: "",
                departmentCode: "",
                spaceName: "",
                folderCode: "",
              };
              this.showAdd = false;
              let arr = JSON.parse(JSON.stringify(this.viewTabs));
              let last = arr.pop();
              this.getFoldersFiles({
                folderCode: last.code,
                departmentCode: last.departmentCode,
                folderType: last.type,
              });
            }
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.file-folder-page {
  width: 100%;
  height: 100%;
  min-height: 6rem;
  background: #fafafa;
  position: relative;
  .view-tabs {
    width: 100%;
    background: #fafafa;
    padding: 0.3rem;
    .view-name {
      font-size: 0.26rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.37rem;
      .cur-name {
        color: #0094ee;
      }
    }
    .opt-btn {
      margin-top: 0.3rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      .new-folder {
        width: 1.28rem;
        height: 0.6rem;
        background: #0094ee;
        border-radius: 0.16rem;
        color: #fff;
        line-height: 0.6rem;
        text-align: center;
      }
      .del-folder {
        margin-left: 0.3rem;
        width: 1.28rem;
        height: 0.6rem;
        background: #e2f0f8;
        border-radius: 0.16rem;
        color: #0094ee;
        line-height: 0.6rem;
        text-align: center;
      }
    }
  }
  .list-box {
    width: 7.5rem;
    min-height: 1rem;
    background: #fff;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    .file-folder {
      width: 100%;

      .item {
        width: 100%;
        height: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 0.01rem solid #e5e5e5;
        padding-left: 0.3rem;
        padding-bottom: 0.3rem;
        padding: 0rem 0.3rem;
        .check-box {
          margin-right: 0.2rem;
        }
        .right-box {
          margin-left: 0.2rem;
          width: 5.5rem;
          // border-bottom: 0.01rem solid #e5e5e5;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .left-info {
            width: 100%;
            .icon {
              width: 0.2rem;
            }
            .item-name {
              font-size: 0.3rem;
              font-weight: 400;
              color: #333333;
              line-height: 0.42rem;
              overflow: hidden;
              text-overflow: ellipsis;
              transition: color 0.3s;
              white-space: nowrap;
              flex: 1;
              width: 5.3rem;
            }
            .item-info {
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-top: 0.08rem;
              .user-name {
                font-size: 0.26rem;
                font-weight: 400;
                color: #999999;
                line-height: 0.37rem;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: color 0.3s;
                white-space: nowrap;
                max-width: 3.2rem;
              }
              .time {
                margin-left: 0.3rem;
                font-size: 0.26rem;
                font-weight: 400;
                color: #999999;
                line-height: 0.37rem;
                width: 2rem;
              }
            }
          }
        }
      }
      .item:last-child {
        border: none;
      }
    }
  }
  .action-sheet {
    width: 100%;
    padding: 0.3rem;
    .action {
      width: 6.9rem;
      height: 0.8rem;

      border-radius: 0.4rem;
      margin-top: 0.3rem;
      line-height: 0.8rem;
      text-align: center;
    }
    .action:first-child {
      margin-top: 0rem;
      color: #fff;
      background: #0094ee;
    }
    .action:nth-child(2) {
      background: #e2f0f8;
      color: #0094ee;
    }
    .action:nth-child(3) {
      background: #fef0f0;
      color: #f56c6c;
    }
    .action:nth-child(4) {
      background: #f0f9eb;
      color: #67c23a;
    }
  }
  .opt-btn-bottom {
    // position: absolute;
    // bottom: 0.3rem;
    // right: 5%;
    // width: 90%;
    // overflow: hidden;
  }
  .btn-box {
    position: absolute;
    bottom: 0.3rem;
    width: 90%;
    right: 5%;
  }
}
</style>
